import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import MainLayout from "../components/main-layout"
import SEO from "../components/seo"
import PlainMark from "../components/icons/plain-mark"

const Printable = ({ image, url, alt, description }) => (
  <div className="w-full flex flex-col items-center justify-center p-2 md:w-1/2 lg:w-1/2">
    <script
      type="text/javascript"
      src="https://payhip.com/payhip.js?v=24u68984"
    ></script>
    <a href={url} target="_blank" className="w-3/4" rel="noopener noreferrer">
      <Image
        fluid={image}
        alt={alt}
        className="rounded-md
                   transform hover:scale-110
                   transition ease-in duration-300"
      />
    </a>
    <a
      href={url}
      target="_blank"
      className="text-xs my-4 px-4 py-2 bg-jnl-yellow-medium rounded-full 
                 hover:bg-jnp-yellow-dark hover:shadow-xl
                 transition ease-in duration-300"
      rel="noopener noreferrer"
    >
      {description}
    </a>
  </div>
)

class Printables extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const weeklyPlanners = data.weeklyPlanPack.img
    const attendanceTracker = data.attendanceTracker.img
    const planningPack = data.planningPack.img

    return (
      <MainLayout
        location={this.props.location}
        title={siteTitle}
        className="bg-gray-100"
      >
        <SEO title="Printables" />
        <PlainMark className="fixed w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
        <PlainMark className="fixed w-24 left-0 top-0 sm:ml-12 lg:ml-32 sm:mt-96 lg:mt-48 opacity-25 text-jnl-red-medium z-0 " />
        <div className="relative z-20 container mx-auto py-8 px-4">
          <div class="bg-white overflow-hidden shadow rounded-lg">
            <div class="border-b border-gray-200 px-4 sm:px-6">
              <h1 className="text-3xl my-4">Printable Homeschool Resources</h1>
            </div>
            <div class="px-4 py-5 sm:p-6">
              <div className="flex sm:flex-col lg:flex-row flex-wrap">
                <Printable
                  description="Homeschool Planning Printable Pack"
                  alt="Joyful Noise Learning Homeschool Planning Sheets and Printables"
                  url="https://payhip.com/b/OehT"
                  image={planningPack.fluid}
                />
                <Printable
                  description="Weekly Planning Sheets Pack (Free / Pay what you want)"
                  alt="Joyful Noise Learning Weekly Planning Sheets Pack"
                  url="https://payhip.com/b/Cs9S"
                  image={weeklyPlanners.fluid}
                />
                <Printable
                  description="Attendance Tracking Sheet (Free / Pay what you want)"
                  alt="Joyful Noise Learning Attendance Tracking Sheet"
                  url="https://payhip.com/b/lBAK"
                  image={attendanceTracker.fluid}
                />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
}

export default Printables

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    weeklyPlanPack: file(
      relativePath: { eq: "printables/2020-weekly-plan-pack.png" }
    ) {
      img: childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    attendanceTracker: file(
      relativePath: { eq: "printables/2020-attendance-tracker.png" }
    ) {
      img: childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    planningPack: file(
      relativePath: { eq: "printables/2020-planning-pack.png" }
    ) {
      img: childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
